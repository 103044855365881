.app__aboutus {
  position: relative;
}
.app__aboutus-content {
  width: 100%;
  z-index: 2;
}
.app__aboutus-content_about {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
}
.app__aboutus-content_history {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}
.knife__img {
  height: 780px;
}
.app__aboutus-content_knife {
  margin: 2rem 4rem;
}
.app__aboutus-content_history p,
.app__aboutus-content_about p {
  margin: 2rem 0;
  color: var(--color-grey);
}
.know__more:hover {
  background: var(--color-golden);
}

@media screen and (max-width: 2000px) {
  .knife__img {
    height: 910px;
  }
  .app__aboutus-content_history p,
  .app__aboutus-content_about p {
    margin: 4rem 0;
  }
}
@media screen and (max-width: 920px) {
  .app__aboutus-content {
    flex-direction: column;
  }
  .knife__img {
    height: 510px;
  }
  .app__aboutus-content_history p,
  .app__aboutus-content_about p {
    margin: 2rem 0;
  }
  .app__aboutus-content_knife {
    margin: 2rem 2rem;
  }
}
@media screen and (max-width: 650px) {
  .app__aboutus-content_knife img{
    height: 270px;
  }
  .app__aboutus-content_history p,
  .app__aboutus-content_about p {
    margin: 1rem 0;
  }
  .app__aboutus-content_knife {
    margin: 1rem 2rem;
    width: 200px;
  }
}
@media screen and (max-width: 1280px) {
  .knife__img {
    height: 650px;
  }
  .app__aboutus-content_knife {
    margin: 1rem 2rem;
  }
}
